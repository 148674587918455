import styled from 'styled-components'

export default styled.div`
  .profile-menu-wrapper {
    .username-image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .profile-popup-wrapper {
      visibility: hidden;
      right: 24px; /*24px is padding-horizontal from header*/
      top: 56px; /*56px is header height*/
      z-index: 2;
      position: absolute;
      width: 304px;
      background: #1e1e1e;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      &.open {
        visibility: visible;
      }
      .profile-section {
        height: 190px;
        flex-direction: column;
        .img-container {
          width: 47px;
          height: 47px;
          margin-bottom: 24px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
        }
        .email-wrapper {
          font-family: 'Prompt', sans-serif;
          font-weight: 500;
          font-size: 18px;
        }
        .role-wrapper {
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
        }
      }
      .option-wrapper {
        width: 100%;
        border-top: 1px solid #660c0d;
        border-bottom: 1px solid #660c0d;
      }
      .option-item {
        padding: 12px 20px;
        background: #242424;
        color: #fff;
        &:first-child {
          border-bottom: 1px solid #660c0d;
        }
        &:hover {
          background: #660c0d;
          cursor: pointer;
        }
        .icon-container {
          width: 24px;
          height: 24px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          &:last-child {
            margin-left: auto;
          }
        }
        .option-text {
          font-family: 'Prompt', sans-serif;
          font-weight: 500;
          font-size: 18px;
          margin-left: 20px;
        }
      }
      .change-language-subheader,
      .language-item {
        padding: 12px 16px;
        background: #242424;
        .icon-container {
          width: 32px;
          height: 32px;
          margin-right: 16px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          &:hover {
            cursor: pointer;
          }
        }
        font-family: 'Prompt', sans-serif;
        font-weight: 500;
        font-size: 18px;
      }
      .language-item {
        &:hover {
          cursor: pointer;
          background: #660c0d;
        }
        &.selected {
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }
`
