import React from 'react'
import PropTypes from 'prop-types'

import ProfileMenuStyled from './styled'

import NextIcon from '../../assets/images/header/next.svg'
import CheckIcon from '../../assets/images/header/selected.svg'
import LogoutIcon from '../../assets/images/header/log-out.svg'

import { withTranslation } from '../../../i18n'

export class ProfileMenu extends React.PureComponent {
  state = {
    isPopUpOpen: false,
    isSubMenuMode: false,
    activeSubMenu: undefined,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('touchend', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('touchend', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this.buttonPopup && !this.buttonPopup.contains(event.target)) {
      this.closePopup()
    }
  }

  closePopup = () => {
    this.setState({
      isPopupOpen: false,
      isSubMenuMode: false,
    })
  }

  togglePopup = () => {
    if (this.props.isLeftbarExpand) this.props.handleProfileClicked()
    this.setState(state => ({ isPopupOpen: !state.isPopupOpen, isSubMenuMode: false }))
  }

  goToFirstMenu = () => {
    this.setState({
      isSubMenuMode: false,
    })
  }

  goToSecondMenu(subMenu) {
    this.setState({
      isSubMenuMode: true,
      activeSubMenu: subMenu,
    })
  }

  onEachMenuClick = option => {
    if (option.subMenu) {
      this.goToSecondMenu(option)
    } else {
      option.onClickFunction()
      this.closePopup()
    }
  }

  renderProfilePicture() {
    const { user } = this.props
    return (
      <div className="username-image" onClick={this.togglePopup}>
        <img src={user.picture} data-test="img-profile" />
      </div>
    )
  }

  renderProfileSection() {
    const { user } = this.props
    return (
      <div className="profile-section flex justify-center align-items-center">
        <div className="img-container">
          <img key="menu-profile-img" src={user.picture} />
        </div>
        <div className="email-wrapper">{user.name}</div>
        <div className="role-wrapper">{user.role?.name}</div>
      </div>
    )
  }

  renderMenuSection() {
    const { popupOptions } = this.props
    const outputMenu = popupOptions.map(option => {
      return (
        <div key={option.label} className="option-item flex" onClick={() => this.onEachMenuClick(option)}>
          <div className="icon-container flex">
            <img src={option.icon} />
          </div>
          <div className="option-text">{option.label}</div>
          {option.subMenu && (
            <div className="icon-container flex">
              <img src={NextIcon} />
            </div>
          )}
        </div>
      )
    })
    return <div className="option-wrapper flex flex-column">{outputMenu}</div>
  }

  renderLogoutMenu() {
    return (
      <div className="option-item  flex" onClick={this.props.handleLogoutClicked}>
        <div className="icon-container flex">
          <img src={LogoutIcon} />
        </div>
        <div className="option-text">{this.props.t('log_out')}</div>
      </div>
    )
  }

  renderProfileFirstMenu() {
    return (
      <>
        {this.renderProfileSection()}
        {this.renderMenuSection()}
        {this.renderLogoutMenu()}
      </>
    )
  }

  onSubMenuClick(onClickFunction) {
    onClickFunction()
    this.goToFirstMenu()
    this.closePopup()
  }

  renderProfileSecondMenu() {
    const { language } = this.props
    const { activeSubMenu } = this.state
    const { subMenu } = activeSubMenu
    const subMenuList = subMenu.options.map(menu => {
      let className = 'language-item flex'
      const isSelectedLanguage = menu.value === language
      className += isSelectedLanguage ? ' selected' : ''

      return (
        <div className={className} key={menu.value} onClick={() => this.onSubMenuClick(() => activeSubMenu.onClickFunction(menu.value))}>
          <div className={'icon-container'}>{isSelectedLanguage && <img src={CheckIcon} />}</div>
          {menu.text}
        </div>
      )
    })
    return (
      <>
        <div className="change-language-subheader flex align-items-center">
          <div className="icon-container" onClick={this.goToFirstMenu}>
            <img key="back-img" src={subMenu.icon} />
          </div>
          {subMenu.title}
        </div>
        {subMenuList}
      </>
    )
  }

  renderProfileMenu() {
    const isClient = typeof window !== 'undefined'
    const windowHeight = isClient ? window.innerHeight : 0
    const optionsBounding = this.popup?.getBoundingClientRect()
    const iconBounding = this.buttonPopup?.getBoundingClientRect()
    const isOptionOverWindow = optionsBounding?.bottom > windowHeight
    const height = this.popup?.clientHeight
    const style = {
      transform: isOptionOverWindow ? `translate(0px, -${optionsBounding.height + iconBounding.height}px)` : 'initial',
      height: this.state.isPopupOpen ? `${height}px` : 'unset',
    }
    let className = 'profile-popup-wrapper'
    className += this.state.isPopupOpen ? ' open' : ''

    const profileMenu = this.state.isSubMenuMode ? this.renderProfileSecondMenu() : this.renderProfileFirstMenu()

    return (
      <div
        ref={ref => {
          this.popup = ref
        }}
        className={className}
        style={style}
      >
        {profileMenu}
      </div>
    )
  }

  renderContent() {
    return (
      <div className="profile-menu-wrapper">
        {this.renderProfilePicture()}
        {this.renderProfileMenu()}
      </div>
    )
  }

  render() {
    return (
      <ProfileMenuStyled
        ref={ref => {
          this.buttonPopup = ref
        }}
      >
        {this.renderContent()}
      </ProfileMenuStyled>
    )
  }
}

ProfileMenu.defaultProps = {
  language: 'EN',
}

ProfileMenu.propTypes = {
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    nickname: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    locale: PropTypes.string,
    updated_at: PropTypes.string,
    sub: PropTypes.string,
    role: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  popupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      onClickFunction: PropTypes.func,
      subMenu: PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.string,
        subMenu: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
  language: PropTypes.string,
  handleLogoutClicked: PropTypes.func.isRequired,
  isLeftbarExpand: PropTypes.bool,
  handleProfileClicked: PropTypes.func,
}

export default withTranslation()(ProfileMenu)
